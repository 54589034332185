<template>
  <div class="layoutPage">
    <HeadInfo :is-login="isLogin" />

    <div class="portalMain" :class="{ portalMainlogin: isLogin }">
      <Nav ref="navSidebar" v-if="!isLogin" />
      <div class="portalContent">
        <div class="contentParty d-flex">
          <div>
            <img
              src="@/assets/img/contactUs.png"
              width="216"
              alt="contactUs"
              class="contactUs"
              style="margin-right: 30px"
              v-if="
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'showPic',
                  'vIf'
                )
              "
            />
          </div>
          <div>
            <div class="loginTitle">{{ $g("contactUs") }}</div>
            <div class="loginDescription" v-html="desc"></div>
          </div>
        </div>

        <k-alert-msg ref="lblMsg" />
        <KValidationObserver ref="observer" class="contentParty d-block">
          <k-form @keydown.enter="isPrevent" @submit.prevent="save">
            <k-form-group
              label-class="require-mark"
              label-suffix="firstName"
              label-for="noneBind"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                name="firstName"
                rules="required|max:200"
                v-slot="{ errors }"
                slim
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input id="firstName" v-model="form.firstName" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="middleName"
              label-for="noneBind"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                name="middleName"
                rules="max:200"
                v-slot="{ errors }"
                slim
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input id="middleName" v-model="form.middleName" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="lastName"
              label-for="noneBind"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              label-class="require-mark"
            >
              <KValidationProvider
                name="lastName"
                rules="required|max:200"
                v-slot="{ errors }"
                slim
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input id="lastName" v-model="form.lastName" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="email"
              label-for="noneBind"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              label-class="require-mark"
            >
              <KValidationProvider
                :rules="{
                  max: 200,
                  email: true,
                  required: true,
                }"
                name="email"
                v-slot="{ errors }"
                slim
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input id="emailAddress" v-model="form.email" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="subject"
              label-for="noneBind"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              label-class="require-mark"
            >
              <KValidationProvider
                name="subject"
                rules="required|max:200"
                v-slot="{ errors }"
                slim
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input id="subject" v-model="form.subject" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="description"
              label-for="noneBind"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              label-class="require-mark"
            >
              <KValidationProvider
                name="description"
                rules="required|max:2000"
                v-slot="{ errors }"
                slim
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-textarea
                  id="input-description"
                  v-model="form.description"
                  rows="5"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-for="noneBind"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              class="wapBtnCenter"
            >
              <div class="d-flex flex-wrap justify-content-around">
                <k-button
                  ref="submitBtn"
                  type="submit"
                  variant="primary"
                  @keydown.enter="save"
                  class="w-150"
                >
                  buttons.submit
                </k-button>
                <k-button
                  @click="clearAction"
                  variant="utility"
                  @keydown.enter="clearAction"
                  class="w-150"
                >
                  buttons.clear
                </k-button>
              </div>
            </k-form-group>
          </k-form>
        </KValidationObserver>
      </div>
    </div>
    <FooterInfo :is-contact-us="false" />
  </div>
</template>

<script>
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import Nav from "@/layout/components/nav";
import { submitContact } from "@/api/contact-us";
import { getMyProfileInfo } from "@/api/myprofile";
import { getPortalPageSetting } from "@/api/home";
import {
  repResult,
  storeage,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";

import { pageNameEnum } from "@/utils/enums/pageNameEnum";

export default {
  components: {
    HeadInfo,
    FooterInfo,
    Nav,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      desc: "",
      form: {
        firstName: "",
        lastName: "",
        middleName: "",
        email: "",
        subject: "",
        description: "",
        pollId: null,
      },
      isLogin: true,
    };
  },
  computed: {},
  watch: {},
  created() {
    getPortalPageSetting(pageNameEnum.ContactUs).then((data) => {
      this.desc = data.data.description;
    });
  },
  mounted() {
    this.getMyProfileInfoApi();
  },
  methods: {
    isPrevent(e) {
      let event = e.srcElement || e.target;
      if (event.nodeName !== "TEXTAREA") {
        e.preventDefault();
      }
    },
    getMyProfileInfoApi() {
      let userId = storeage.getUserInfo()?.userId;
      if (userId) {
        getMyProfileInfo(userId).then((res) => {
          if (res) {
            this.$refs.observer.reset();
            this.form.firstName = res.data.firstName;
            this.form.lastName = res.data.lastName;
            this.form.middleName = res.data.middleName;
            this.form.email = res.data.email;
            this.form.subject = "";
            this.form.description = "";
            this.form.pollId = "";
          }
        });
        this.isLogin = false;
      } else {
        this.$refs.observer.reset();
        this.form.firstName = "";
        this.form.lastName = "";
        this.form.middleName = "";
        this.form.email = "";
        this.form.subject = "";
        this.form.description = "";
        this.form.pollId = "";
      }
    },
    clearAction() {
      this.form = {};
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(() => {
          submitContact(this.form).then((res) => {
            this.$refs.lblMsg.message(res.data.status, res.data.info);
            if (res.data.status == repResult.success) {
              this.getMyProfileInfoApi();
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.portalMainlogin {
  width: 950px;
  margin: 0 auto;
  min-height: auto;
  padding-top: 0;
}
.loginTitle {
  font-size: 36px;
  font-weight: bold;
}
.loginDescription {
  font-size: 16px;
}
@media (min-width: 1025px) {
  .portalMainlogin {
    .portalContent {
      padding-left: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .contactUs {
    width: 100px;
  }
  .portalMainlogin {
    width: 95%;
  }
  .loginTitle {
    font-size: 18px;
  }
  .loginDescription {
    font-size: 14px;
  }
}
</style>
